import {
  alpha,
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import HubspotContactForm from "../src/modules/components/HubSpotContactForm";
import AppAppBar from "../src/modules/components/AppAppBarSimple";
import { PaletteMode } from "@mui/material";
const mode = "light";
const toggleColorMode = () => {
  console.log("toggled");
};
const landing = () => {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        backgroundImage:
          theme.palette.mode === "light"
            ? "linear-gradient(180deg, #CEE5FD, #FFF)"
            : "linear-gradient(#02294F, #090E10)",
        backgroundSize: "100% 20%",
        backgroundRepeat: "no-repeat",
      })}
    >
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8}>
            <div>
              <Typography
                component="h3"
                variant="h3"
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "column" },
                  alignSelf: "left",
                  textAlign: "top",
                  verticalAlign: "top",
                }}
              >
                Prueba 1 mes gratis
                <Typography
                  component="h5"
                  variant="h5"
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "column" },
                    alignSelf: "left",
                    textAlign: "top",
                    verticalAlign: "top",
                  }}
                >
                  Ingresa tu información de contacto en el formulario adjunto
                  <br></br> y recibiras en tu correo electrónico la
                  instrucciones para iniciar <br></br>{" "}
                  <span>tu prueba gratis.</span>
                </Typography>
              </Typography>
              <Box
                id="image"
                sx={(theme) => ({
                  mt: { xs: 8, sm: 10 },
                  alignSelf: "center",
                  height: { xs: 300, sm: 800 },
                  width: "100%",
                  backgroundImage:
                    theme.palette.mode === "light"
                      ? 'url("../../images/LogoLanding.png")'
                      : 'url("../../images/LogoLanding.png")',
                  backgroundSize: "90%",

                  backgroundRepeat: "no-repeat",
                  borderRadius: "10px",
                  backgroundPosition: "center",
                })}
              />
            </div>
          </Grid>
          <Grid xs={12} sm={12} md={4}>
            <HubspotContactForm
              region="na1"
              portalId="47127435"
              formId="def21aac-bdbc-488b-9cd9-31c9517b13cf"
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default landing;
