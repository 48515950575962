import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Home";
import LandingPage from "./landing";
import TagManager from "react-gtm-module";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-KJZSNVBG",
    };
    TagManager.initialize(tagManagerArgs);
  }, []);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/landing" element={<LandingPage />} />
      </Routes>
    </div>
  );
}

export default App;
