import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

const tiers = [
  {
    title: "Gratis",
    price: "0",
    description: [
      "1 a 3 empleados",
      "12 meses de historia de pagos",
      "Cálculo de aguinaldo con la información de pagos incluidos en el sistema",
      "No incluye soporte técnico",
    ],
    buttonText: "Iniciar",
    buttonVariant: "outlined",
  },
  {
    title: "Profesional",
    subheader: "Recomendado",
    price: "10",
    description: [
      "4 a 20 empleados",
      "Planilla en USD o colones",
      "5 años de historial de pagos",
      "Creacion de archivo de pago de banco",
      "Reportes contables (retenciones, provisiones, aguinaldo)",
      "Soporte por correo electrónico",
    ],
    buttonText: "Iniciar",
    buttonVariant: "contained",
  },
  {
    title: "Corporativo",
    price: "30",
    description: [
      "21 empleados en adelante",
      "Todos los beneficios del plan profesional mas",
      "Soporte preferencial",
      "Centros de Costos",
      "Gestion de Vacaciones y permisos",
      "Multicompañías",
      "Multiplanillas",
    ],
    buttonText: "Contactenos",
    buttonVariant: "outlined",
  },
];

export default function Pricing() {
  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Precios
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Nuestra estructura de precios diseñada para empresas de todo tamaño
          <br />
          Contactenos si tiene dudas o necesidades específicas.
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="center" justifyContent="left">
        {tiers.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === "Enterprise" ? 12 : 6}
            md={4}
          >
            <Card
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                gap: 4,
                border: tier.title === "Profesional" ? "1px solid" : undefined,
                borderColor:
                  tier.title === "Profesional" ? "primary.main" : undefined,
                background:
                  tier.title === "Profesional"
                    ? "linear-gradient(#033363, #021F3B)"
                    : undefined,
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color:
                      tier.title === "Profesional"
                        ? "primary.contrastText"
                        : "",
                  }}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                  {tier.title === "Profesional" && (
                    <Chip
                      icon={<AutoAwesomeIcon />}
                      label={tier.subheader}
                      size="small"
                      sx={{
                        background: (theme) =>
                          theme.palette.mode === "light" ? "" : "none",
                        backgroundColor: "primary.contrastText",
                        "& .MuiChip-label": {
                          color: "primary.dark",
                        },
                        "& .MuiChip-icon": {
                          color: "primary.dark",
                        },
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    color:
                      tier.title === "Profesional"
                        ? "primary.contrastText"
                        : undefined,
                  }}
                >
                  <Typography component="h3" variant="h2">
                    {tier.title !== "Corporativo" ? tier.price : ""}
                  </Typography>
                  <Typography component="h3" variant="h6">
                    {tier.title !== "Corporativo"
                      ? " $ por empleado por mes"
                      : "Contactenos"}
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: "grey.500",
                  }}
                />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: "flex",
                      gap: 1.5,
                      alignItems: "left",
                      textAlign: "left",
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color:
                          tier.title === "Profesional"
                            ? "primary.light"
                            : "primary.main",
                      }}
                    />
                    <Typography
                      component="text"
                      variant="subtitle2"
                      sx={{
                        color:
                          tier.title === "Profesional"
                            ? "primary.contrastText"
                            : undefined,
                      }}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant as "outlined" | "contained"}
                  component="a"
                  href="/landing"
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
